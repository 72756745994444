import React, {
  Component,
  lazy,
  Suspense,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import './App.css';
import { getForecast, getCachedForecast } from './api';
import { CSSTransition } from 'react-transition-group';

const Location = lazy(() => import('./Location'));
const Summary = lazy(() => import('./Summary'));
const Details = lazy(() => import('./Details'));
const Hourly = lazy(() => import('./Hourly'));
const Daily = lazy(() => import('./Daily'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
  }

  componentDidUpdate() {
    persistState(this.state);
  }

  selectMetric = (selection) => {
    const { metric, metrics } = this.state;
    let toggle;

    if (selection !== metric) {
      return this.setState({ metric: selection });
    }

    switch (selection) {
      case 'temperature':
        toggle = 'temperatureApparent';
        break;
      case 'temperatureApparent':
        toggle = 'temperature';
        break;
      case 'precipitationIntensity':
        toggle = 'dewPoint';
        break;
      case 'dewPoint':
        toggle = 'precipitationIntensity';
        break;
      case 'windSpeed':
        toggle = 'humidity';
        break;
      case 'humidity':
        toggle = 'windSpeed';
        break;
      case 'uvIndex':
        toggle = 'pressureSurfaceLevel';
        break;
      case 'pressureSurfaceLevel':
        toggle = 'uvIndex';
        break;
      default:
    }

    const index = metrics.indexOf(selection);
    const newMetrics = [...metrics];

    newMetrics[index] = toggle;

    this.setState({
      metric: toggle,
      metrics: newMetrics,
    });
  };

  render() {
    const { forecast, loading } = this.props;
    if (!forecast) return null;

    const { metric, metrics } = this.state;
    const { lat, lon } = forecast.location;
    const { minutely, hourly } = forecast.timelines;
    const { precipType } =
      (minutely && minutely.find(({ precipType }) => precipType)) ||
      (hourly && hourly.find(({ precipType }) => precipType)) ||
      {};

    const options = { metric, metrics, precipType };

    return (
      <div className={`forecast ${loading ? 'loading' : ''}`}>
        <Location latitude={lat} longitude={lon} />

        <CSSTransition in={!!forecast} timeout={200} classNames="summary">
          <Summary forecast={forecast} options={options} />
        </CSSTransition>

        <div className="ranges">
          <div>
            <Details
              forecast={forecast}
              options={options}
              onSelectMetric={this.selectMetric}
            />
            <Hourly forecast={forecast} options={options} />
          </div>
          <Daily forecast={forecast} metric={metrics[0]} />
        </div>
      </div>
    );
  }
}

const spinner = document.getElementById('loading');

const AppLoader = () => {
  const visibility = useDocumentVisibility();
  const [loading, setLoading] = useState(false);
  const [forecast, setForecast] = useState(getCachedForecast());

  useEffect(() => {
    if (loading) spinner.classList.remove('complete');

    const timer = setTimeout(() => {
      if (!loading) spinner.classList.add('complete');
    }, 250);
    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    if (visibility === 'hidden') return;

    async function updateForecast() {
      const cached = getCachedForecast();
      if (cached) return setForecast(cached);

      setLoading(true);
      const forecast = await getForecast();
      setForecast(forecast);
      setLoading(false);
    }

    updateForecast();
  }, [visibility]);

  if (!forecast) return null;

  return (
    <Suspense fallback={null}>
      <App forecast={forecast} loading={loading} />
    </Suspense>
  );
};

const defaultState = {
  metric: 'temperatureApparent',
  metrics: [
    'temperatureApparent',
    'precipitationIntensity',
    'humidity',
    'uvIndex',
  ],
};

function getInitialState() {
  const state = JSON.parse(localStorage.getItem('state')) || {};

  return {
    ...defaultState,
    ...state,
  };
}

function persistState(state) {
  localStorage.setItem('state', JSON.stringify(state));
}

function useEventListener(eventName, handler, target = global) {
  const listener = useRef();

  useEffect(() => (listener.current = handler), [handler]);

  useEffect(() => {
    if (!target || !target.addEventListener) return;

    const eventListener = (event) => listener.current(event);
    target.addEventListener(eventName, eventListener);
    return () => target.removeEventListener(eventName, eventListener);
  }, [eventName, target]);
}

function useDocumentVisibility() {
  const [visibility, setVisibility] = useState(document.visibilityState);
  const handler = useCallback(
    () => setVisibility(document.visibilityState),
    [setVisibility]
  );
  useEventListener('visibilitychange', handler, document);
  return visibility;
}

export default AppLoader;
