import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    reg?.update();
    window.location.reload(true);
  },
});
